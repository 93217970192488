@import url("banks.css");

:root {
  --primary-color: #ffda22;
  --font-regular: "fc_minimalregular", sans-serif;
  --font-regulari: "fc_minimalitalic", sans-serif;
  --font-bold: "fc_minimalbold", sans-serif;
  --font-boldi: "fc_minimalbold_italic", sans-serif;
}

body,
html {
  height: 100%;
}

body {
  font-size: 1.25rem;
  background-color: #ddd;
  line-height: 24px;
  color: #fff;
  font-family: var(--font-regular);
}

a {
  color: var(--primary-color);
  transition: all 0.5s;
}

a:hover {
  color: #fff;
}

.bgMain {
  background-image: url("./images/bg-main.jpg");
  background-repeat: repeat-x;
  background-size: cover;
  background-position: center 0;
  background-color: #01082c;
  height: 100%;
  width: 100%;
}

.boxTopRounded {
  -webkit-border-radius: 80px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius: 80px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-radius: 80px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #28195a;
  border: 2px solid #8878be;
  padding: 85px 20px 40px;
  position: relative;
  margin-bottom: 20px;
}

.topBMargin {
  margin-top: 35%;
}

.logoTopAb {
  top: -10%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.titleH2 {
  font-size: 3rem;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--primary-color);
}

.formBox {
  position: relative;
}

.inputBox {
  border-radius: 50px;
  background-color: #1a0f40;
  border: 2px solid #ffda22;
  color: #fff;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.inputBox:focus {
  background-color: #100a2469;
  border-color: #ffda22ad;
  box-shadow: 0 0 0 0.25rem #ffc10736;
  color: var(--primary-color);
}

v .form-control::-moz-placeholder {
  color: #ffffff;
}

.form-control::placeholder {
  color: #ffffff;
}

.inputLeft {
  padding-left: 50px;
}

.iconLeft {
  position: absolute;
  left: 22px;
  top: 10px;
}

.btnPrimary {
  background: rgb(255, 218, 34);
  background: linear-gradient(180deg,
      rgba(255, 218, 34, 1) 0%,
      rgba(255, 59, 0, 1) 30%,
      rgba(196, 39, 71, 1) 90%,
      rgba(112, 21, 145, 1) 100%);
  display: block;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 1.4rem;
  font-family: var(--font-bold);
  padding: 10px 15px;
  border-radius: 50px;
  border: 2px solid #280930;
}

.btnFullWidth {
  width: 100%;
}

footer {
  padding: 50px 20px 20px 20px;
  text-align: center;
}

.backlink {
  display: inline-block;
  padding: 20px 0;
}

.inputInline {
  border-radius: 10px;
  display: inline-block;
  width: 48px;
  margin-right: 5px;
  text-align: center;
}

.colorlink {
  color: var(--primary-color);
}

.titleOutBox {
  font-size: 2rem;
  color: var(--primary-color);
  margin-top: 20px;
}

.boxSmRounded {
  border-radius: 10px;
  padding: 20px;
  margin-top: 0;
}

.imgRight {
  width: 100px;
  left: 90%;
  transform: translate(-50%, -50%);
  top: -12px;
}

.titleWithLine {
  border-bottom: 2px solid #8878be;
  padding-bottom: 8px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.form-check-input,
.form-check-input:checked {
  border: 2px solid #ffda22;
  background-color: #1a0e40;
}

.bgInfo {
  background-color: #1b1040;
  padding: 10px 10px 10px 50px;
  position: relative;
  border-radius: 10px;
  font-size: 0.95rem;
  line-height: 1rem;
  margin-bottom: 15px;
}

.bgInfo img {
  position: absolute;
  top: 10px;
  left: 13px;
}

.select7__icon {
  margin-right: 15px;
}

.select7__drop {
  font-size: inherit;
  background-color: #1a0e40;
  border-width: 2px;
  border-style: solid;
  border-color: #ffda22;
  border-radius: 15px;
}

.select7__option,
.select7__optgroup {
  color: #ffffff;
}

.topDash {
  position: relative;
}

.topDashLogo {
  width: 160px;
  top: 18px;
  left: 88%;
}

.colHi h1 {
  font-size: 3rem;
  padding-top: 26px;
  font-family: var(--font-bold);
  color: var(--primary-color);
  margin-bottom: 0;
  line-height: 2.5rem;
}

.colHi h4 {
  font-size: 1.6rem;
  margin-bottom: -2px;
  font-family: var(--font-bold);
}

.colHi span {
  display: block;
  font-size: 1.2rem;
}

.miniBtn {
  border: 1px solid #ffda22;
  padding: 1px 10px;
  font-size: 1rem;
  display: inline-block;
  margin-top: 6px;
  border-radius: 50px;
  text-decoration: none;
}

.miniBtn:hover {
  border: 1px solid #fff;
  color: #fff;
}

.normalPad {
  padding: 40px 20px;
}

.rowCredit {
  display: flex;
  margin-bottom: 20px;
}

.iconCredit {
  width: 68px;
}

.colCredit {
  flex: 1;
  padding-left: 20px;
}

.colCredit h2 {
  font-family: var(--font-bold);
  font-size: 3.5rem;
  color: var(--primary-color);
  line-height: 48px;
}

.rowBtn {
  display: flex;
  margin-bottom: 20px;
}

.btnDeposit,
.btnWithdraw {
  flex: 1;
  text-align: center;
}

.bgOverlay {
  background-color: #28195a99;
}

.pr-2 {
  padding-right: 5px;
}

.pl-2 {
  padding-left: 5px;
}

.btnBlue {
  background: rgb(139, 240, 217);
  background: linear-gradient(180deg,
      rgba(139, 240, 217, 1) 0%,
      rgba(119, 140, 255, 1) 30%,
      rgba(39, 93, 196, 1) 90%,
      rgba(34, 25, 139, 1) 100%);
}

.rowBonus {
  display: flex;
  margin-bottom: 20px;
}

.colBonus,
.colTurn {
  flex: 1;
}

.colTurn {
  text-align: right;
}

.rowFree {
  display: flex;
  align-items: flex-end;
}

.rowFreeno {
  padding-right: 10px;
}

.rowFreeno h5,
.noTurn {
  font-size: 2rem;
  margin-bottom: 0;
  line-height: 1.4rem;
  color: var(--primary-color);
}

.noTurn span {
  color: #fff;
}

.btnPlay {
  position: absolute;
  display: block;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -82px;
}

.btnPlay img {
  width: 300px;
}

.mbDash {
  margin-bottom: 90px;
}

.bgMenu {
  -webkit-border-radius: 10px;
  -webkit-border-bottom-right-radius: 40px;
  -webkit-border-bottom-left-radius: 40px;
  -moz-border-radius: 10px;
  -moz-border-radius-bottomright: 40px;
  -moz-border-radius-bottomleft: 40px;
  border-radius: 10px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  background-color: #28195a;
  border: 2px solid #8878be;
  text-align: center;
  padding: 10px;
  margin-bottom: 45px;
  position: relative;
  min-height: 90px;
}

.bgMenu a {
  text-decoration: none;
  display: block;
  padding-top: 34px;
  line-height: 1rem;
  font-size: 1.2rem;
}

.bgMenu a:hover {
  color: #fff;
}

.bgMenu a .iconMenu {
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 0;
  transition: all 0.5s;
}

.bgMenu a:hover .iconMenu {
  top: -10px;
}

.iconBackAb {
  padding: 0;
  position: absolute;
  left: 10px;
  top: 6px;
}

.bgBar {
  background: rgb(235, 216, 184);
  background: linear-gradient(180deg,
      rgba(235, 216, 184, 1) 0%,
      rgba(222, 193, 68, 1) 30%,
      rgba(210, 129, 9, 1) 90%,
      rgba(201, 41, 12, 1) 100%);
  padding: 16px 10px;
  color: #0f0a21;
  text-align: center;
  position: relative;
  font-size: 1.4rem;
  font-family: var(--font-bold);
  -webkit-border-bottom-right-radius: 16px;
  -webkit-border-bottom-left-radius: 16px;
  -moz-border-radius-bottomright: 16px;
  -moz-border-radius-bottomleft: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.miniToplogo {
  margin-top: 100px;
}

.miniToplogo .logoTopAb {
  width: 150px;
  top: -6px;
}

.btnRight {
  position: absolute;
  top: 3px;
  right: 13px;
}

.inputRight {
  padding-right: 75px;
}

.topLabel {
  padding-left: 15px;
  margin-bottom: 5px;
}

.rowLabel {
  display: flex;
}

.rowLabel .topLabel {
  flex: 1;
}

.rowBank {
  display: flex;
  align-items: center;
}

.colBankname {
  flex: 1;
  font-size: 1.6rem;
}

.iconBank img {
  width: 48px;
  margin-right: 15px;
  border-radius: 50%;
}

.rowName {
  margin: 15px 0;
}

.rowName h4 {
  font-size: 1.8rem;
  font-family: var(--font-bold);
  color: var(--primary-color);
  margin-bottom: 0;
}

.rowName span {
  font-size: 1.5rem;
}

.rowVerify {
  font-size: 1rem;
}

.rowVerify img {
  margin-right: 10px;
}

.modal-content {
  background-color: #28195a;
  border: 2px solid #8878be;
  padding: 20px;
  width: 90%;
}

.modal-dialog-centered {
  justify-content: space-evenly;
}

.iconClose {
  position: absolute;
  right: 20px;
  top: 10px;
}

.inModal {
  padding-bottom: 20px;
}

.form-control:disabled {
  background-color: #1a0f40;
  border-color: #4e3991;
  color: #6a59a3;
}

.mb20 {
  margin-top: 20px;
}

.imgBonus {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.imgBonus img {
  width: 100%;
  border-radius: 10px;
}

.smRemark {
  font-size: 0.95rem;
  line-height: 18px;
  display: inline-block;
  margin-top: 15px;
  font-style: italic;
  color: #7161a7;
}

.smRemark span {
  color: var(--primary-color);
}

.bgUserTop {
  background: rgb(235, 216, 184);
  background: linear-gradient(180deg,
      rgba(235, 216, 184, 1) 0%,
      rgba(222, 193, 68, 1) 30%,
      rgba(210, 129, 9, 1) 90%,
      rgba(201, 41, 12, 1) 100%);
  -webkit-border-radius: 10px;
  -webkit-border-bottom-right-radius: 60px;
  -webkit-border-bottom-left-radius: 60px;
  -moz-border-radius: 10px;
  -moz-border-radius-bottomright: 60px;
  -moz-border-radius-bottomleft: 60px;
  border-radius: 10px;
  border-bottom-right-radius: 60px;
  border-bottom-left-radius: 60px;
  border: 2px solid #280930;
  position: relative;
  padding: 20px;
  color: #28185d;
}

.bgUserTop h4 {
  font-size: 2rem;
  font-family: var(--font-bold);
  margin-bottom: 0;
  line-height: 28px;
}

.bgUserTop span {
  display: block;
}

.miniDark {
  border: 2px solid #28195d;
  color: #28195d;
  font-family: var(--font-bold);
}

.miniDark:hover {
  border: 2px solid #fff;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .bgMain {
    background-size: auto;
    background-position: 90% 40%;
  }

  .bgMain2 {
    background-image: url("./images/bg-main2.jpg");
    background-position: center top;
    background-size: cover;
    background-color: #2a1f80;
  }

  .topBMargin {
    margin-top: 50%;
  }

  .boxSmRounded {
    margin-top: 0;
  }

  .mb20 {
    margin-top: 20px;
  }

  .topDashLogo {
    width: 160px;
    top: 18px;
    left: 80%;
  }
}