i.bank {
  display: inline-block;
  border-radius: 20%;
  background-color: grey;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  padding: 0.1em;
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: center;
  background-origin: content-box;
  overflow: hidden;
  vertical-align: -0.23em;
}

i.bank.round {
  border-radius: 50%;
}

i.bank.shadow {
  box-shadow: 0px 0px 5px grey;
}

i.bank.huge {
  width: 64px;
  height: 64px;
}

i.bank,
i.bank.xxxl {
  width: 32px;
  height: 32px;
}

i.bank,
i.bank.xxl {
  width: 22px;
  height: 22px;
}

i.bank,
i.bank.xl {
  width: 18px;
  height: 18px;
}

i.bank,
i.bank.l {
  width: 16px;
  height: 16px;
}

i.bank,
i.bank.s {
  width: 12px;
  height: 12px;
}

i.bank,
i.bank.xs {
  width: 10px;
  height: 10px;
}

i.bank.bank-bbl {
  background-color: #1e4598;
  background-image: url("./images/banks/bbl.svg");
}

i.bank.bank-kbank {
  background-color: #138f2d;
  background-image: url("./images/banks/kbank.svg");
}

i.bank.bank-rbs {
  background-color: #032952;
  background-image: url("./images/banks/rbs.svg");
}

i.bank.bank-ktb {
  background-color: #1ba5e1;
  background-image: url("./images/banks/ktb.svg");
}

i.bank.bank-jpm {
  background-color: #321c10;
  background-image: url("./images/banks/jpm.svg");
}

i.bank.bank-mufg {
  background-color: #d61323;
  background-image: url("./images/banks/mufg.svg");
}

i.bank.bank-tmb {
  background-color: #1279be;
  background-image: url("./images/banks/tmb.svg");
}

i.bank.bank-scb {
  background-color: #4e2e7f;
  background-image: url("./images/banks/scb.svg");
}

i.bank.bank-citi {
  background-color: #1583c7;
  background-image: url("./images/banks/citi.svg");
}

i.bank.bank-smbc {
  background-color: #a0d235;
  background-image: url("./images/banks/smbc.svg");
}

i.bank.bank-sc {
  background-color: #0f6ea1;
  background-image: url("./images/banks/sc.svg");
}

i.bank.bank-cimb {
  background-color: #7e2f36;
  background-image: url("./images/banks/cimb.svg");
}

i.bank.bank-uob {
  background-color: #0b3979;
  background-image: url("./images/banks/uob.svg");
}

i.bank.bank-uob {
  background-color: #0b3979;
  background-image: url("./images/banks/uob.svg");
}

i.bank.bank-bay {
  background-color: #fec43b;
  background-image: url("./images/banks/bay.svg");
}

i.bank.bank-mega {
  background-color: #815e3b;
  background-image: url("./images/banks/mega.svg");
}

i.bank.bank-boa {
  background-color: #e11e3c;
  background-image: url("./images/banks/boa.svg");
}

i.bank.bank-cacib {
  background-color: #0e765b;
  background-image: url("./images/banks/cacib.svg");
}

i.bank.bank-gsb {
  background-color: #eb198d;
  background-image: url("./images/banks/gsb.svg");
}

i.bank.bank-hsbc {
  background-color: #fd0d1b;
  background-image: url("./images/banks/hsbc.svg");
}

i.bank.bank-db {
  background-color: #0522a5;
  background-image: url("./images/banks/db.svg");
}

i.bank.bank-ghb {
  background-color: #f57d23;
  background-image: url("./images/banks/ghb.svg");
}

i.bank.bank-baac {
  background-color: #4b9b1d;
  background-image: url("./images/banks/baac.svg");
}

i.bank.bank-mb {
  background-color: #150b78;
  background-image: url("./images/banks/mb.svg");
}

i.bank.bank-bnp {
  background-color: #14925e;
  background-image: url("./images/banks/bnp.svg");
}

i.bank.bank-tbank {
  background-color: #fc4f1f;
  background-image: url("./images/banks/tbank.svg");
}

i.bank.bank-ibank {
  background-color: #184615;
  background-image: url("./images/banks/ibank.svg");
}

i.bank.bank-tisco {
  background-color: #12549f;
  background-image: url("./images/banks/tisco.svg");
}

i.bank.bank-kk {
  background-color: #199cc5;
  background-image: url("./images/banks/kk.svg");
}

i.bank.bank-icbc {
  background-color: #c50f1c;
  background-image: url("./images/banks/icbc.svg");
}

i.bank.bank-tcrb {
  background-color: #0a4ab3;
  background-image: url("./images/banks/tcrb.svg");
}

i.bank.bank-lhb {
  background-color: #6d6e71;
  background-image: url("./images/banks/lhb.svg");
}

i.bank.bank-ttb {
  background-color: #ecf0f1;
  background-image: url("./images/banks/ttb.svg");
}

i.bank.bank-true {
  background-color: #ecf0f1;
  background-image: url("./images/banks/tmn.svg");
}

i.bank.bank-pp {
  background-color: #00427a;
  background-image: url("./images/banks/pp.svg");
}

.checkbox-changer {
  background-color: rgb(255, 207, 255) !important;
  color: white;
}

.checkbox-changer.active {
  background-color: rgb(245 90 241) !important;
}