@font-face {
  font-family: "fc_minimalbold_italic";
  src: url("./fonts/fc_minimal_bold_italic-webfont.woff2") format("woff2"),
    url("./fonts/fc_minimal_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fc_minimalbold";
  src: url("./fonts/fc_minimal_bold-webfont.woff2") format("woff2"),
    url("./fonts/fc_minimal_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fc_minimalitalic";
  src: url("./fonts/fc_minimal_italic-webfont.woff2") format("woff2"),
    url("./fonts/fc_minimal_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fc_minimalregular";
  src: url("fonts/fc_minimal_regular-webfont.woff2") format("woff2"),
    url("fonts/fc_minimal_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@keyframes twinkling {
  0% {
    box-shadow: 0 0 5px #fff, 0 0 10px #ff0000;
  }

  50% {
    box-shadow: 0 0 10px #ff0000, 0 0 15px #ff6f00, 0 0 20px #ff0000, 0 0 25px #ff0000;
  }

  100% {
    box-shadow: 0 0 5px #fff, 0 0 10px #ff0000;
  }
}

.twinkling-button {
  animation: twinkling 1.5s infinite;
}